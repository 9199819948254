import {
  type EditorPlatformApp,
  type EditorSDK,
} from '@wix/platform-editor-sdk';
import type {
  ExperimentsBag,
  EditorReadyFn,
  FlowAPI,
  TFunction,
} from '@wix/yoshi-flow-editor';
import { getAppManifestFactory } from './editor-script/app-manifest/app-manifest';
import {
  onWidgetAdded,
  onSiteSaved,
  onComponentDeleted,
  onSitePublished,
  onAppVisitedInDashboard,
} from './editor-script/event-handlers';
import { maybeInstallEcom } from './editor-script/ecom';

let sdk: EditorSDK;
let appToken: string;
let flowApi: FlowAPI;
let t: TFunction;
let locale: string;
let experiments: ExperimentsBag;
let responsive: boolean;

interface FormAppInterface extends Omit<EditorPlatformApp, 'editorReady'> {
  editorReady: EditorReadyFn;
}

const editorApp: FormAppInterface = {
  editorReady: async (editorSDK, token, platformOptions, _flowApi) => {
    flowApi = _flowApi;
    sdk = editorSDK;
    appToken = token;
    t = flowApi.translations.t as any;
    experiments = flowApi.experiments.all();

    flowApi.bi.updateDefaults({
      target_account_id: platformOptions.initialAppData.userId,
    });

    await maybeInstallEcom({ sdk, flowApi });

    onAppVisitedInDashboard({ sdk, flowApi });

    onWidgetAdded({
      sdk,
      appToken,
      flowApi,
    });
    onComponentDeleted({
      sdk,
      bi: flowApi.bi,
      appToken,
    });
    onSiteSaved({ sdk, appToken, flowApi });
    onSitePublished({ sdk, appToken, bi: flowApi.bi });
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => locale,
    () => appToken,
    () => sdk,
    () => experiments,
    () => responsive,
    () => flowApi.httpClient,
    () => flowApi.fedops,
  ),
};

export const editorReady = editorApp.editorReady;
export const getAppManifest = editorApp.getAppManifest;
